import axios from "axios";

const API_URL = "/api/";

class ServiceAuth{

    
    async login(login,password){
        try {
            const result = await axios.post(API_URL+'login',{login, password})
            if(result.status === 200 /*&& result.data[1].accessToken !== undefined*/ ){
                // sessionStorage.setItem("userInfo", JSON.stringify(result.data[0]));
                return result.data
            }
        }catch (error) {
            // console.log(error)
            switch(error.response.status){
                case 401:
                    throw error.response.data.error
                case 500:
                    throw error.response.statusText
                default:
                    throw "erreur inconnu"
            }
        }
        
    }

    async resetPassword(login) {
        
        const result = await axios.post(API_URL+'password-handler/reset-password',{login})
        // console.log(result.data)
        return result.data
        // window.location.reload(true)
    }

    async changePassword(login, token) {
        
        try {
            const result = await axios.post(API_URL+'password-handler/change-password',{login, token})
            return result.data
        } catch (error) {
            switch(error.response.status){
                case 401:
                    throw error.response.data.error
                case 404:
                    throw error.response.data.error
                case 500:
                    throw error.response.statusText
                default:
                    throw "erreur inconnu"
            }
        }

        // window.location.reload(true)
    }

    async logout() {
        
        const result = await axios.get(API_URL+'logout')
        sessionStorage.removeItem("userInfo")
        // window.location.reload(true)
    }

    getCurrentUser() {
        return JSON.parse(sessionStorage.getItem('userInfo'))
    }
}


export default new ServiceAuth();