import React, { useContext, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import ServiceAuth from '../services/service_auth'
import { AuthContext } from '../Context/authProvider';

function ResetPassword() {

    const {state} = useLocation()
    const navigate = useNavigate()
    const [authError, setAuthError] = useState('')
    const [success, setSuccess] = useState('')
    const {auth, authDispatch} = useContext(AuthContext)
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-4">
                <h1 className="text-center mt-2">Mot de passe oublié</h1>
                <Formik
                    initialValues={{ login: '' }}
                    validationSchema={Yup.object({
                        login: Yup.string()
                            .required('Un identifiant est nécessaire'),
                    })}
                    onSubmit={async ({ login }) => {
                        try {
                            let data = await ServiceAuth.resetPassword(login)
                            setSuccess(data.message)
                        } catch (error) {
                            // console.log(error)
                            setAuthError(error)
                        } 
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {success && <div className="alert alert-info">{success}</div>}
                            {authError && <div className="alert alert-danger">{authError}</div>}
                            <div className="mb-3">
                                <label htmlFor="login" className="form-label">Identifiant</label>
                                <Field type="text" className="form-control" id="login" name="login" autoComplete="username"/>
                                <ErrorMessage name="login" component="div" className="alert alert-warning" />
                            </div>
                            <div className="d-grid gap">
                                <button type="submit" className="btn btn-primary btn-expand" disabled={isSubmitting}>Valider</button>
                            </div>
                            <div className="d-grid gap" style={{marginTop: 5 + 'px'}}>
                                <p><br/>Vous avez oublié votre identifiant ?<br/> 
                                Pour rappel, votre identifiant est celui qui se trouve indiqué 
                                dans le mail que vous avez reçu (objet: Accès à socotex-espace-pro).<br/>
                                </p>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassword