import React, { useCallback, useContext } from 'react'
import '../styles/footer.css'
import { Link } from 'react-router-dom'

function Footer() {


    return (
        <footer className="footer">
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css" rel="stylesheet"/>
            <link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css" rel="stylesheet"/>
            <div className="container text-center">
                <p>© 2024 - Socotex - Espace Pro - Reproduction interdite - Les photos, dessins, les schémas d'implantation et plans sont la propriété industrielle de Socotex</p>
                <h3>retrouvez-nous</h3>
                <ul className="list-group list-group-horizontal justify-content-center">
                    <a href="https://www.pinterest.fr/speyrucq/_saved/" target="_blank" rel="noreferrer"><i className="fa fa-pinterest"></i></a>
                    <a href="https://www.facebook.com/socotex/" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                    <a href="https://www.linkedin.com/company/socotex/" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                    <a href="https://twitter.com/socotex" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a>
                    <a href="https://www.instagram.com/socotex.honfleur/" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                </ul>
                <p>SOCOTEX - Toiles et Structures - Zone Industrielle - 14600 Honfleur - France - Tél : (+33) 02 31 81 61 10 - <a href='mailto:flux@socotex' style={{border:0,padding:0,fontSize:'16px'}}>Nous contacter par mail</a></p>
            </div>
            <div>

                <Link to="mentions-legales" style={{'border':'0'}}><u>Mentions légales</u></Link>
            </div>
        </footer>
    );
}

export default Footer;