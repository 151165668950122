import React, { useEffect, useState, useContext } from 'react'
import ServiceAuth from '../services/service_auth'
import { Navigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ServiceAdmin from '../services/service_admin'

function AdminExport () {

  // const user = ServiceAuth.getCurrentUser()

  const handleSubmit = async (event) =>{

    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);   
    const formJson = Object.fromEntries(formData.entries());
    console.log("formJson",formJson)
    await ServiceAdmin.getSubmissionData(formJson.submitId)
 
  }

  async function handleButtonOnClick(event){
    // console.log(event.target.innerText)
    let returnObject = await ServiceAdmin.getExecQuery()
    // console.log(returnObject)
  }
  
  return (
    <div className="list-group">
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formSoumissionId">
          <Form.Label>ID de la soumission</Form.Label>
          <Form.Control type="text" placeholder="Entrez l'ID de la soumission" name="submitId" required/>
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <button id="facture" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Execute Query</button>
    </div>

  )
}

export default AdminExport