import React from 'react'


function MentionsLegales () {

  return (
    <div className="list-group ms-4" style={{'textAlign':'left'}}>
        <p style={{'fontSize':'18px','margin':'0'}}><b>SOCOTEX</b></p>
        <p style={{'margin':'0'}}>Toiles & Structures</p>
        <p style={{}}><b>#Abritez-vouscheznous</b></p>
        <p style={{'margin':'0'}}>Zone Industrielle</p>
        <p style={{'margin':'0'}}>Rue Jean Lepeudry</p>
        <p style={{'margin':'0'}}>14600 Honfleur</p>
        <p style={{'margin':'0'}}>Tel : 02 31 81 61 10</p>
        <p style={{'margin':'0'}}>Fax : 02 31 89 21 67</p>
        <p style={{}}>info@socotex.fr</p>
        <p style={{'margin':'0'}}>S.A.S. au capital de 100 000 euros</p>
        <p style={{'margin':'0'}}>siret 477 250 047 000 16</p>
        <p style={{}}>NAF 1392 Z -RC Honfleur B 477 250 047</p>
        <p style={{}}>TVA intracommunautaire FR 83 477 250 047</p>
        <p style={{'margin':'0'}}>Conception du site et reponsable de la publication :</p>
        <p style={{'margin':'0'}}>Arthur Frebourg</p>
        <p style={{}}>afrebourg@socotex.fr</p>
        <p style={{'margin':'0'}}>Hébergement : pulseheberg.com</p>
    </div>
  )
}

export default MentionsLegales