import React, { useContext, useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import ServiceAuth from '../services/service_auth'
import { AuthContext } from '../Context/authProvider';

function ChangePassword() {

    const {state} = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [authError, setAuthError] = useState('')
    const [success, setSuccess] = useState('')
    const token = searchParams.get('token')
    
    return (
        <div className="d-flex justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-4">
                <h1 className="text-center mt-2">Mot de passe oublié</h1>
                <Formik
                    initialValues={{ newPassword: '', newPasswordValidation: ''}}
                    validationSchema={Yup.object({
                        newPassword: Yup.string()
                        .required('Un nouveau mot de passe est nécessaire')
                        .min(12, "le mot de passe doit faire au moins 12 caractères")
                        .matches(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?,;'])"), "le mot de passe doit contenir au moins 1 majuscule, 1 miniscule, 1 nombre et 1 caractère spécial"),
                        newPasswordValidation: Yup.string()
                        .required('La confirmation du mot de passe est nécessaire')
                        .oneOf([Yup.ref('newPassword')], 'Les mots de passe ne sont pas identique')
                    })}
                    onSubmit={async ({ newPassword }) => {
                        try {
                            let data = await ServiceAuth.changePassword(newPassword, token)
                            setSuccess(data.message)
                            navigate('/login',{state:{success: data.message}})
                        } catch (error) {
                            setAuthError(error)
                        } 
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            {success && <div className="alert alert-info">{success}</div>}
                            {authError && <div className="alert alert-danger">{authError}</div>}
                            <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">Nouveau mot de passe</label>
                                <Field type="password" className="form-control" id="newPassword" name="newPassword" />
                                <ErrorMessage name="newPassword" component="div" className="alert alert-warning" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="newPasswordValidation" className="form-label">Confirmation du mot de passe</label>
                                <Field type="password" className="form-control" id="newPasswordValidation" name="newPasswordValidation" />
                                <ErrorMessage name="newPasswordValidation" component="div" className="alert alert-warning" />
                            </div>
                            <div className="d-grid gap">
                                <button type="submit" className="btn btn-primary btn-expand" disabled={isSubmitting}>Valider</button>
                            </div>
                            <div className="d-grid gap" style={{marginTop: 5 + 'px'}}>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ChangePassword