import React, { useEffect, useState, useContext } from 'react'
import { useSearchParams, Link } from "react-router-dom";


function Accueil () {

  
  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success")
  const error = searchParams.get("error")

  return (
    <div className="list-group">
      {success && <div className="alert alert-info">{success}</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      <h1 className="text-center mt-2">Bienvenue sur Socotex espace pro</h1>
      <div>
        <div className="d-grid gap" style={{marginTop: 5 + 'px'}}>
          <p>Cet espace commande est réservé aux clients de l'entreprise Socotex.
              Si vous avez déjà crée votre compte sur l'espace pro,<Link to='/login'> <b>connectez-vous!</b></Link><br/>
              Si vous avez un compte client : vous allez recevoir un e-mail : Accès à socotex-espace-pro
                  <br/>Si vous n'êtes pas encore client : rendez-vous sur notre formulaire 
                  <a href="https://www.socotex.net/contact-socotex" target="_blank" rel='noreferrer noopener'> <b>Contact</b> </a>, vous serez contacté
                  par le commercial en charge de votre secteur pour la mise en place de votre compte client.
          </p>
        </div>
        <div>
          <video className='videoSoco' src={process.env.PUBLIC_URL + '/socotex_savoir-faire.mp4'} controls autoPlay muted>
            <p>Socotex présente son savoir-faire</p>
          </video>
        </div>
        <div style={{'display':'inline-flex'}}>
        <a className='accueilLink' href='https://socotex.net' target="_blank" rel='noreferrer noopener'><h3 style={{backgroundColor:"rgb(33,88,112)", color:"white",padding:'0 0.4em'}}>Découvrir nos produits</h3></a>
        <a className='accueilLink' href='https://www.socotex.net/contact-socotex' target="_blank" rel='noreferrer noopener'><h3 style={{backgroundColor:"white", color:"rgb(33,88,112)"}}>&nbsp; Nous contacter &nbsp;</h3></a>
        </div>
        {/* <a href='https://www.socotex.net/blog' target="_blank" rel="noopener noreferrer" style={{'position':'absolute', 'top':0, left:0, 'display':'inline-block', 'width':'100%', 'height':'100%', 'zIndex':5}}>
        </a> */}
      </div>
    </div>
  )
}

export default Accueil