import React from 'react'

function NotFound () {
  return (
    <div>
        <h1 className="text-center mt-2">404 Error</h1>
        <h3 className="text-center">Page not Found</h3>
    </div>
  )
}

export default NotFound