import axios from "axios";

const API_URL = "/api/";

class ServiceRegister{

    
    async validate_pass(login,password){
        try {
            const result = await axios.post(API_URL+'validate',{login, password})
            // console.log(result)
            if(result.status === 200){
                return result.data
            }
        }catch (error) {
            // console.log(error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }
    async register_new_password(login, tempPass, newPass) {
        
        try {
            const result = await axios.post(API_URL+'register',{login, tempPass, newPass})
            if(result.status === 200){
                return result.data
            }
        }catch (error) {
            // console.log(error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                case 409:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
        }
    }

}


export default new ServiceRegister();