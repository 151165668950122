import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../context'
import ServiceAuth from '../services/service_auth'
import { Navigate } from 'react-router-dom'

function AccueilAdmin () {

  const user = ServiceAuth.getCurrentUser()

  return (
    <div className="list-group">
        <><h1 className="text-center mt-2">Espace Administrateur</h1><ul className="list-group list-group-flush">
          <li className="list-group-item"> nom: {user?.username}</li>
          <li className="list-group-item"> email: {user?.email}</li>
          <li className="list-group-item"> role: {user?.role}</li>
        </ul></>
    </div>

  )
}

export default AccueilAdmin