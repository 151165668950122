import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useLocation } from 'react-router-dom'
import ServiceRegister from '../services/service_register'
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

function Register() {

    const {state} = useLocation()
    const navigate = useNavigate()
    const [registerError, setRegisterError] = useState('')
    const [registerSuccess, setRegisterSuccess] = useState('')
    const [successName, setSuccessName] = useState('')
    const [successTemp, setSuccessTemp] = useState('')
    const [type1, setType1] = useState('password');
    const [type2, setType2] = useState('password');
    const [icon1, setIcon1] = useState(eyeOff);
    const [icon2, setIcon2] = useState(eyeOff);

    const handleToggle1 = () => {
        if (type1==='password'){
           setIcon1(eye);
           setType1('text')
        } else {
           setIcon1(eyeOff)
           setType1('password')
        }
    }

    const handleToggle2 = () => {
        if (type2==='password'){
           setIcon2(eye);
           setType2('text')
        } else {
           setIcon2(eyeOff)
           setType2('password')
        }
    }

    return (
<div className="d-flex justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-4">
                <h1 className="text-center mt-2">Creation du Compte</h1>
                {
                    registerSuccess ?
                    registerSuccess && <div className="alert alert-info">{registerSuccess}</div>
                    : 


                    successName ?
                        <Formik
                        validationSchema={Yup.object({
                            login: Yup.string()
                                .required('Un identifiant est nécessaire'),
                            password: Yup.string()
                                .required('Un mot de passe est nécessaire'),
                            newPassword: Yup.string()
                                .required('Un nouveau mot de passe est nécessaire')
                                .min(12, "le mot de passe doit faire au moins 12 caractères")
                                .matches(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?,;'])"), "le mot de passe doit contenir au moins 1 majuscule, 1 minuscule, 1 nombre et 1 caractère spécial (!@#$%^&*?,;')"),
                            newPasswordValidation: Yup.string()
                                .required('La confirmation du mot de passe est nécessaire')
                                .oneOf([Yup.ref('newPassword')], 'Les mots de passe ne sont pas identique')


                                
                        })}
                        onSubmit={async ({ login, password, newPassword, newPasswordValidation }) => {
                            try {
                                setRegisterError()
                                // console.log("onSubmit1")
                                let data = await ServiceRegister.register_new_password(login, password, newPassword)
                                if (data === "success"){ 
                                    setRegisterSuccess("Votre compte a été crée avec succès, vous allez recevoir un mail de validation à l'Email par défaut du compte (cf. mail: 'Accès à socotex-espace-pro') pour activer votre compte. Vous pouvez fermer cette page.")
                                }
                            } catch (error) {
                                // console.log(error)
                                setRegisterError(error.error)
                            } 
                        }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    {/* {state?.errMsg && <div className="alert alert-danger">{state?.errMsg}</div>} */}
                                    {registerSuccess && <div className="alert alert-info">{registerSuccess}</div>}
                                    {registerError && <div className="alert alert-danger">{registerError}</div>}
                                    <div className="mb-3">
                                        <label htmlFor="login" className="form-label">Identifiant</label>
                                        <Field type="text" className="form-control" id="login" name="login" readOnly/>
                                        <ErrorMessage name="login" component="div" className="alert alert-warning" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">Code temporaire</label>
                                        <Field type={'password'} className="form-control" id="password" name="password" readOnly/>
                                        <ErrorMessage name="password" component="div" className="alert alert-warning" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="newPassword" className="form-label">Nouveau mot de passe</label>
                                            <Field type={type1} style={{'display':'inline-block'}} className="form-control" id="newPassword" name="newPassword"></Field>
                                            <span class="" onClick={handleToggle1}>
                                                <Icon style={{'position':'absolute', 'marginLeft':'-30px','marginTop':'4px'}} icon={icon1} size={25}/>
                                            </span>
                                        <ErrorMessage name="newPassword" component="div" className="alert alert-warning" />
                                        
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="newPasswordValidation" className="form-label">Confirmation du mot de passe</label>
                                        <Field type={type2} style={{'display':'inline-block'}} className="form-control" id="newPasswordValidation" name="newPasswordValidation" />
                                            <span class="" onClick={handleToggle2}>
                                                <Icon style={{'position':'absolute', 'marginLeft':'-30px','marginTop':'4px'}} icon={icon2} size={25}/>
                                            </span>
                                        <ErrorMessage name="newPasswordValidation" component="div" className="alert alert-warning" />
                                    </div>
                                    <div className="d-grid gap">
                                        <button type="submit" className="btn btn-primary btn-expand" disabled={isSubmitting}>Valider</button>
                                    </div>
                                    {/* <div className="d-grid gap" style={{marginTop: 25 + 'px'}}>
                                        <p>Cet espace commande est réservé aux clients de l'entreprise Socotex.
                                            Si vous avez un compte client : vous allez recevoir un e-mail : accès à socotex-espace-pro
                                            Si vous n'êtes pas encore client : rendez-vous sur notre formulaire 
                                            <a href="https://www.socotex.net/contact-socotex"> Contact </a>, vous serez contacté
                                            par le commercial en charge de votre secteur pour la mise en place de votre compte client.
                                        </p>
                                    </div> */}
                                </Form>
                            )}
                        </Formik>
                    :
                    <Formik
                    initialValues={{ login: '', password: '', newPassword: '', newPasswordValidation: '' }}
                    validationSchema={Yup.object({
                        login: Yup.string()
                            .required('Un identifiant est nécessaire'),
                        password: Yup.string()
                            .required('Un mot de passe est nécessaire'),
                            
                    })}
                    onSubmit={async ({ login, password }) => {
                        try {
                            // console.log("onSubmit2")
                            let data = await ServiceRegister.validate_pass(login, password)
                            setSuccessName(login)
                            setSuccessTemp(password)
                            setRegisterError()
                        } catch (error) {
                            setRegisterError(error.error)
                        } 
                    }}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                {/* {state?.errMsg && <div className="alert alert-danger">{state?.errMsg}</div>} */}
                                {registerError && <div className="alert alert-danger">{registerError}</div>}
                                <div className="mb-3">
                                    <label htmlFor="login" className="form-label">Identifiant</label>
                                    <Field type="text" className="form-control" id="login" name="login" />
                                    <ErrorMessage name="login" component="div" className="alert alert-warning" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Code temporaire</label>
                                    <Field type="password" className="form-control" id="password" name="password" />
                                    <ErrorMessage name="password" component="div" className="alert alert-warning" />
                                </div>
                                <div className="d-grid gap">
                                    <button type="submit" className="btn btn-primary btn-expand" disabled={isSubmitting}>Valider</button>
                                </div>
                                <div className="d-grid gap" style={{marginTop: 25 + 'px'}}>
                                    {/* <p>Cet espace commande est réservé aux clients de l'entreprise Socotex.
                                        Si vous avez un compte client : vous allez recevoir un e-mail : accès à socotex-espace-pro
                                        Si vous n'êtes pas encore client : rendez-vous sur notre formulaire 
                                        <a href="https://www.socotex.net/contact-socotex"> Contact </a>, vous serez contacté
                                        par le commercial en charge de votre secteur pour la mise en place de votre compte client.
                                    </p> */}
                                </div>
                            </Form>
                        )}
                    </Formik>
                }

            </div>
        </div>
    )
}

export default Register