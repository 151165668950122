import React, { useState, useEffect } from 'react'
import ServiceQuery from '../services/service_query'
import { useNavigate, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

function ValidateOrder () {

  const navigate = useNavigate()
  const {state} = useLocation()
  const [selectedOrder, setSelectedOrder] = useState(state.orderId)
  const [addressList, setAddressList] = useState(null)
  const [addressId, setAddressId] = useState(null)
  const [addressDetails, setAddressDetails] = useState(null)
  const [ordersList, setOrdersList] = useState(null)
  const [queryError, setQueryError] = useState("")
  
  let details = undefined

  let options = [];

  useEffect(() => {
    async function callService() {
      
      let returnObjectOrders = await ServiceQuery.getOrdersIdList('validateOrder')
      setOrdersList(returnObjectOrders)
      let returnObjectAddress = await ServiceQuery.getAddressList()
      setAddressList(returnObjectAddress)
    }
    callService()
  }, []);
  
  const changeAddressOptionHandler = async (event) => {
    setAddressDetails(null)
    setAddressId(event.target.value)
    if(event.target.value !== '' && event.target.value !== 'Autre'){
      details = await ServiceQuery.getAddressInfo(event.target.value)
      setAddressDetails(details)
    }else{
      // setAddressDetails(null)
    }
    console.log(addressId)
  }
  
  const changeOrderSelectHandler = async (e) => {
    setSelectedOrder(e.target.value)
  }

  const handleSubmit = async (event) =>{
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);   
    const formJson = Object.fromEntries(formData.entries());
    // console.log(formJson)
    try {
      var formUrl = await ServiceQuery.getFormValidateUrl(formJson.order, formJson.address)
    } catch (error) {
      setQueryError(error)
    }
    navigate('/formulaire',{state:{formUrl, formName: 'Validation commande', order_id: formJson.order }})

  }

  return (
     <div style={{'textAlign':'center', 'display':'flex','justifyContent':'center','alignItems':'center','flexDirection':'column'}}>
      <div style={{'width':'fit-content','textAlign':'left'}}>
        
        {queryError && <div className="alert alert-danger">{queryError}</div>}
        
        <form id="formFormulaire" onSubmit={handleSubmit}>
          <h1 className="text-center mt-2">Validation commande</h1><ul className="list-group list-group-flush"></ul>
          <div className="list select-div">
          <label htmlFor="order">Choisissez la commande :</label>
          <select className="form-select form-select-lg select-second-item" value={selectedOrder} style={{'width':'fit-content','display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} name="order" id="order" onChange={changeOrderSelectHandler}>
            {ordersList}
          </select>
          </div>
          <div className="list select-div">
            <label htmlFor="address">Choisissez une adresse de livraison:</label>
            <select className="form-select form-select-lg select-second-item" style={{'width':'fit-content', 'display':'inline', 'margin': '0.5em', 'borderColor':'#333'}} form="formFormulaire" name="address" id ="address" required onChange={changeAddressOptionHandler}>
              <option value="" hidden>Adresse</option>
              {addressList}
              <option style={{ 'color':'blue'}}value="Autre">Nouvelle adresse</option>
            </select>
          </div>
          <div className="" style={{'textAlign':'center','marginTop':'1em'}}>
            <button type="submit" style={{paddingLeft:'1.5em',paddingRight:'1.5em'}}className="btn btn-primary" disabled={''}>Valider</button>
          </div>
        </form>
      </div>
      {
          addressDetails?.sage__Rue__c ? 
          <>

            <Table style={{'marginTop':'20px'}} striped  hover>
              <thead>
                <tr>
                  <th key='Adresse'>Adresse</th>
                  <th key='Code postal'>Code postal</th>
                  <th key='Ville'>Ville</th>
                  <th key='Pays'>Pays</th>
                </tr>
              </thead>
              <tbody>
              <tr key='address'>
                <td>{addressDetails.sage__Rue__c}</td>
                <td>{addressDetails.sage__Code_postal__c}</td>
                <td>{addressDetails.sage__Ville__c}</td>
                <td>{addressDetails.sage__Pays2__c}</td>
              </tr>
              </tbody>
            </Table>
        </>
        :
        
          addressId === "Autre" ? 
          <>
            <div style={{'marginTop':'20px'}} className="alert alert-info">Vous pourrez entrer la nouvelle adresse dans le formulaire de commande</div>
          </> 
          : 
          <>
          </>
        }
    </div>

  ) 
    
}

export default ValidateOrder