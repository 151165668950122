import React, { useState, useEffect } from 'react'
import ServiceQuery from '../services/service_query'
import { useNavigate } from 'react-router-dom';

function Documents () {

    
    const [lignesList, setLignesList] = useState(null)
    const [header, setHeader] = useState(null)
    const [docStatus, setDocStatus] = useState('')
    const [caption, setCaption] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function callService() {
            // console.log("in callService")
            let returnObject = await ServiceQuery.getUserDocuments('bon_de_commande')
            // console.log(returnObject)
            if(returnObject !== null){
                setHeader(returnObject.header)
                setLignesList(returnObject.rows)
                setDocStatus('')
                setCaption('Bon de commandes')
            }else{
                setHeader(null)
                setLignesList(null)
                setCaption('Bon de commandes')
                setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
            }
            // console.log(lignes)
        }
        callService()
      }, []);
    
    async function handleButtonOnClick(event){
        // console.log(event.target.innerText)
        let returnObject = await ServiceQuery.getUserDocuments(event.target.id)
        // console.log(returnObject)
        if(returnObject !== null){
            setHeader(returnObject.header)
            setLignesList(returnObject.rows)
            setCaption(event.target.innerText)
            setDocStatus('')
        }else{
            setHeader(null)
            setLignesList(null)
            setCaption(event.target.innerText)
            setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
        }
    }

    return (
        <div>
            <div className='container text-center' style={{padding:"15px"}}>
                <div className='row' >
                    {/* <div className='col'>
                        <button id="opportunity" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Opportunités</button>
                    </div> */}
                    <div className='col'>
                        <button id="bon_de_commande" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Bon de commandes</button>
                    </div>
                    <div className='col'>
                        <button id="bon_de_livraison" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Bon de livraisons</button>
                    </div>
                    <div className='col'>
                        <button id="facture" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Factures</button>
                    </div>
                </div>
            </div>
                <div>
                    <h2>{caption}</h2>
                    {docStatus && <div className="alert alert-warning">{docStatus}</div>}
                    <div className='table-div'>
                        <table className='table table-striped caption-top'>
                            <thead>
                                {header}
                            </thead>
                            <tbody>
                                {lignesList}
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>

    )
}

export default Documents