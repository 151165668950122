import React, { useCallback, useEffect, useState } from 'react'
import ServiceQuery from '../services/service_query'
import ServiceSubmit from '../services/service_submit'
import { useLocation, useNavigate } from 'react-router-dom';

function Formulaire () {

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };

    const navigate = useNavigate()
    const {state} = useLocation()
    if(!state?.formUrl){
        navigate("/choixproduit")
    }

    var submitCount = 0
    // submitCount++
    var buildedSource = state.formUrl

    useEffect(() => {
        var ifr
        ifr = document.getElementById("JotFormIFrame");
        const handler = (event) => {
            if (event.origin === "https://form.jotform.com" ) {
                var args = event.data.split(":")
                switch(args[0]){
                    case "setHeight":
                    ifr.style.height = args[1] + "px";
                    break;
                    default:
                        break
                }
            }
            if (event.origin === "https://eu-submit.jotform.com"){
                switch (event.data.action) {
                    case "submission-completed":
                        if (submitCount === 0) {
                            submitCount++
                            // console.log("submit reçu")
                            handleSubmit()
                        }
                        break
                    default:
                        break
                }
            }
            // console.log("hauteur modifié")
            
        }
        window.addEventListener('message', handler)
    
        return () => window.removeEventListener('message', handler)
    }, [])

    const handleSubmit = async() => {
        // console.log('submitCount',submitCount)
        if(submitCount === 1){
            submitCount++
            // console.log("execute call")
            try {
                // let returnMsg = await ServiceSubmit.postNewSubmit()
                // console.log("returnMsg",returnMsg)
                // if(returnMsg === "success"){navigate("/accueilclient")}
                await sleep(2000)
                if(state.order_id == null){navigate("/accueilclient")}
                else{navigate('/commandes',{state:{order_id:state.order_id}})}
            } catch (error) {
                // console.log('handlesubmit error',error)
            }
        }
    }




  return (
    <div>
      <iframe
            id="JotFormIFrame"
            title={state.formName}
            onLoad={() => {window.parent.scrollTo(0,0)}
            }
            allowtransparency="true"
            allowFullScreen={true}
            allow="geolocation; microphone; camera"
            src={buildedSource}
            style={{
                minWidth: 100 +'%',
                height: 1000 +'px',
                border: 'none',
                overflow: 'hidden'
                
            }}
        >
        </iframe>
    </div>

  )
}

export default Formulaire