import axios from "axios";
import { Context } from "../context";
import { redirect, useNavigate } from "react-router-dom";


const API_URL = "/api/";

class ServiceSubmit{

    
    async postNewSubmit(){
        try {
            console.log("start postNewSubmit")
            const result = await axios.post(API_URL + "submit-handler",{ withCredentials: true 
            })
            if(result.status === 200){
                console.log('submit Result',result)
                return result.data
            }
        }catch (error) {
            console.log('submit error',error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }

}


export default new ServiceSubmit();